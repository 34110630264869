import { StylesContext } from '@material-ui/styles';
import React, { useState, useEffect} from 'react';
import services from '../../services';
import './styles.scss'

const PasswordStrength = ( { password } ) => {

    const [passwordStrength, setPasswordStrength] = useState(0)

    const passwordSizeValidation = services.validate.validePasswordSize(password);
    const passwordNumberValidation = services.validate.validePasswordNumber(password);
    const passwordCapitalValidation = services.validate.validePasswordCapital(password);
    const passwordSpecialValidation = services.validate.validePasswordSpecial(password);

    useEffect( () => {
        let strength = 0;
        if (passwordSizeValidation) { strength++; }
        if (passwordNumberValidation) { strength++; }
        if (passwordCapitalValidation) { strength++; }
        if (passwordSpecialValidation) { strength++; }
        setPasswordStrength (strength);
    },[password])

    return (
        <div className='col-sm-12'>
            <p><b>Força da sua senha</b></p>
            <div className="passwordOutterWrapper">
                <div className="passwordWrapper">
                    <div className={ passwordStrength > 0 ? "passwordBlock passwordActive" : "passwordBlock"}/>
                </div>
                <div className="passwordWrapper">
                    <div className={ passwordStrength > 1 ? "passwordBlock passwordActive" : "passwordBlock"}/>
                </div>
                <div className="passwordWrapper">
                    <div className={ passwordStrength > 2 ? "passwordBlock passwordActive" : "passwordBlock"}/>
                </div>
                <div className="passwordWrapper">
                    <div className={ passwordStrength > 3 ? "passwordBlock passwordActive" : "passwordBlock"}/>
                </div>
            </div>
            <ul className='lista-senha'>
                <li className={ passwordSizeValidation && "active" }>
                Conter uma quantidade de caracteres entre 8 e 20
                </li>
                <li className={ passwordNumberValidation && "active"}>
                    Conter números
                </li>
                <li className={ passwordCapitalValidation && "active"}>
                    Conter letras maiúsculas e minúsculas
                </li>
                <li className={ passwordSpecialValidation && "active"}>
                    Conter caracteres especiais
                </li>
            </ul>
        </div>
    )
}

export default PasswordStrength