import React, { useState } from 'react';

import '../styles.scss';
import services from '../../../services';

import { useDispatch } from "react-redux"
import { 
    //showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    //showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { trackPromise } from 'react-promise-tracker';
import Modal from '../../../components/Modal/Modal';

const FormCodigoParceiroAuto = ({formParceiro, setFormParceiro, modalStatus, setModalStatus}) => {

  const dispatch = useDispatch();
  //const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  //const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [indicarErros, setIndicarErros] = useState(false)

  const back = () => {
    setModalStatus({
      ...modalStatus,
      codigoParceiroAuto: false,
      primeiroAcesso: true,
    })
  }

  const codigoParceiroNaBase = () => {

    if(!formParceiro.codigo){
      showMsgE('Campo Código é obrigatório')
      setIndicarErros(true)
      return;
    }

    setIndicarErros(false);

    let formatObt = {
      cnpj: services.mask.unMask(formParceiro.documento),
      codigo: formParceiro.codigo
    }

    trackPromise (
      services.apiAuto.codigoParceiro(formatObt)
      .then(res => {

        if (!res.fail) {
          if (res.data == null){
            showMsgE('Código não encontrado.')
          }
          else{
            setModalStatus({
              ...modalStatus,
              completarCadastroAuto: true,
              codigoParceiroAuto:false,
            })
          }
        } else {
          //setFormCompletarCadastroParceiro({...formCompletarCadastroParceiroInitialState})
          showMsgE('Código não encontrado.')
        }
      }).catch(error => {
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }

  const changeHandler = (event) => {
    setFormParceiro({
      ...formParceiro,
      codigo: event.target.value
    })
  }

  return (
    <Modal 
      open={modalStatus.codigoParceiroAuto} 
      close={ () => {setModalStatus({...modalStatus, codigoParceiroAuto: false})} } 
      size="lg"
    >
      <div className="pageBody">
        <div className="container-fluid confirmarCadastro">

          <div className="row">
            <div className='col text-left'>
              <h1><b>Primeiro acesso</b></h1>
            </div>
          </div>

          <div className="row">
            <div className='col-sm-12'>
              <FormControl  >
                <TextField label="Informe o Código do Parceiro" type="text"  size="small"
                  onChange={changeHandler}
                  value={formParceiro.codigo}
                  helperText={indicarErros && !formParceiro.codigo && 'Campo obrigatório'}
                  error={indicarErros && !formParceiro.codigo ? true : false}
                  inputProps={{ maxLength: 6 }} 
                  variant="filled"
                  required
                />
              </FormControl>
            </div>
          </div>

          <div className="row">
            <div className='col-12 padB20'>
              <button className="btn btnContinuarIrPrimeiroCadastro btn-lg btn-block" onClick={codigoParceiroNaBase}>
                Confirmar
              </button>
            </div>
          </div>
          <div className="row">
            <div className='col-12 padB20'>
              <button className="btn btnVoltarIrPrimeiroCadastro btn-lg btn-block" onClick={back}>
                Voltar
              </button>
            </div>
          </div>

        </div>
      </div>
    </Modal>
  );
}

export default FormCodigoParceiroAuto;