import React, { memo }  from 'react';
import './styles.scss';

import imgExcel from '../../assets/icons/excel-download.png';
import Tooltip from '@material-ui/core/Tooltip';

const ExcelDownload = ({action, label}) => {
    
    return (
        <div className="excelBtnDownload">
            <Tooltip title={label ? label : 'Extrair planilha'}>
                <img onClick={action} src={imgExcel} alt='' />
            </Tooltip>
        </div>
    )
}

export default memo(ExcelDownload);