export function validaCnpj(cnpj) {
  // Remover caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.slice(0, 14);

  // Verificar se o CNPJ está vazio ou possui tamanho diferente de 14
  if (cnpj === "" || cnpj.length < 14) {
    return false;
  }

  // Eliminar CNPJs inválidos conhecidos
  const cnpjInvalidosConhecidos = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];

  if (cnpjInvalidosConhecidos.includes(cnpj)) {
    return false;
  }

  // Validar dígitos verificadores
  const calcularDigitoVerificador = (parteCnpj) => {
    let soma = 0;
    let pos = parteCnpj.length - 7;

    for (let i = parteCnpj.length; i >= 1; i--) {
      soma += parteCnpj.charAt(parteCnpj.length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultado;
  };

  const digitos = cnpj.substring(12);

  if (
    calcularDigitoVerificador(cnpj.substring(0, 12)) !==
      parseInt(digitos.charAt(0), 10) ||
    calcularDigitoVerificador(cnpj.substring(0, 13)) !==
      parseInt(digitos.charAt(1), 10)
  ) {
    return false;
  }

  return true;
}
