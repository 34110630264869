import React, { useEffect } from 'react';
import moment from 'moment'
import { Calendar, Badge } from 'antd';

/////////////////////////////
// redux
import { useDispatch } from "react-redux"
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './styles.scss';

import AgendamentoHorario from '../../../components/Evento/AgendamentoHorario';
import AgendamentoItemCalendarFull from '../../../components/Evento/AgendamentoItemCalendarFull';
import Modal from '../../../components/Modal/Modal';

import { useGetMeses } from '../../../hooks/useGetMeses';
import { useGetAnos } from '../../../hooks/useGetAnos';

moment.updateLocale('pt', {
  weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]
});

const horariosDisponiveis = [
  '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
]

const currentDay = new Date().getDate()
const anoInit = new Date().getFullYear()
const mesInit = new Date().getMonth()

const localization = {
  "lang": {
    "locale": "pt_BR",
    "placeholder": "Selecionar Data",
    "rangePlaceholder": ["Data Inicio", "Data Fim"],
    "today": "Hoje",
    "now": "Agora",
    "backToToday": "Voltar para hoje",
    "ok": "Ok",
    "clear": "Limpar",
    "month": "Mês",
    "year": "Ano",
    "timeSelect": "Selecionar Tempo",
    "dateSelect": "Selecionar Data",
    "monthSelect": "Escolha um mês",
    "yearSelect": "Escolha um ano",
    "decadeSelect": "Escolha uma decada",
    "yearFormat": "YYYY",
    "dateFormat": "M/D/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
    "previousMonth": "Mês anterior (PageUp)",
    "nextMonth": "Próximo mês (PageDown)",
    "previousYear": "Ano Passado (Control + left)",
    "nextYear": "Próximo ano (Control + right)",
    "previousDecade": "Decada passada",
    "nextDecade": "Próxima decada",
    "previousCentury": "Próximo seculo",
    "nextCentury": "Seculo passado",
  },
  "timePickerLocale": {
    "placeholder": "Selecionar tempo"
  },
  "dateFormat": "YYYY-MM-DD",
  "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
  "weekFormat": "YYYY-wo",
  "monthFormat": "YYYY-MM"
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0
  },
});

const Reagendamento = ({changes, vaga}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setHoraActive('')
    setOpenModal(true) 
  };
  const closeModal = () => { setOpenModal(false) };

  const meses = useGetMeses();
  const anos = useGetAnos();

  const [horaActive, setHoraActive] = React.useState('');
  const [dataSelecionadaString, setDataSelecionadaString] = React.useState('');
  const [dataSelecionada, setDataSelecionada] = React.useState(moment(`${anoInit}-${mesInit + 1}-${currentDay}`));
  const [agendamento, setAgendamento] = React.useState([]);
  const [ano, setAno] = React.useState(anoInit);
  const [mes, setMes] = React.useState(mesInit);

  const selectDate = (event) => {
    setDataSelecionada(event)
  }

  const selecionarHoraDia = (hora) => {
    setHoraActive(hora)
    console.log('====',  hora, dataSelecionadaString )
    // 16:00 2021-08-31T03:00:00.000Z
    //2021-07-30T15:00:00.762Z
    let dataFormat = `${dataSelecionadaString.substring(0, 10)}T${hora}:00.762Z`
    let agendamento = dataFormat
    changes(agendamento)
    setAgendamento([{dataAgenda: dataFormat}])
  }
   

  function getListData(value) {
    let calendarDay = value.toISOString().substring(0,10)
    let listData;
    let eventosDia = agendamento.filter(x => calendarDay === x.dataAgenda.substring(0,10))

    if (eventosDia.length > 0) {
      let getDayAgendamento = eventosDia[0].dataAgenda.substring(11,16)
      listData = [{ content: getDayAgendamento }];
    }
    return listData || [];
  }

  function disabledDate(date) {
    //let calendarDay = date.toISOString().substring(0,10)
    
    let d = new Date().getDate()
    let a = new Date().getFullYear()
    let m = new Date().getMonth()

    let start = `${a}-${m + 1}-${d}`;
    let end = '';
    if (vaga.dataDisponivelAte) { // desabilita data anterior ao dia atual
      end = vaga.dataDisponivelAte.substring(0, 10)
    }
    if (date < moment(start)) { // desabilita data apos disponibilidade
      return true;
    } else if (date > moment(end)){
      return true;
    }
    if (moment(date).day() === 0) { // desabilita domingos
      return true
    }
     
    return false
  }

  function headerRender(e) {
    return null
  }

  function excludeAgendaDetalhe() {
    changes('', 'dataAgendamento')
    setAgendamento([])
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <AgendamentoItemCalendarFull 
        listData={listData} 
        action={getAgendaDetalhe} 
        exclude={excludeAgendaDetalhe} 
        date={value} 
      />
    );
  }

  const getAgendaDetalhe = (dataEvent) => {
    setDataSelecionadaString(dataEvent.toISOString())
    handleOpenModal()
  }

  const getAgenda = (ano, mes) => {
    setDataSelecionada(moment(`${ano}-${mes + 1}-${currentDay}`))
  }

  const confirmarHorario = () => {
    if(!horaActive) showMsgW('Selecione um horário')
    closeModal()
  }
 
  useEffect(() => {
    getAgenda(ano, mes)
  }, [ano, mes]);
  
  return (
    <div className="row">

      <div className="col-md-12 text-center mt40 mb40">
        <h3><strong>Remarque sua entrevista</strong></h3>
        <p>Selecione um período disponível e agende agora mesmo.</p>
      </div>

      <div className='col-md-4 offset-md-2'>
        <FormControl>
          <InputLabel>Ano</InputLabel>
          <Select value={ano} onChange={event => setAno(event.target.value)}>
            {
              anos.map(ano => {
                return (
                  <MenuItem key={ano.nome} value={ano.nome} > {ano.nome} </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </div>
      <div className='col-md-4'>
        <FormControl>
          <InputLabel>Mês</InputLabel>
          <Select value={mes} onChange={event => setMes(event.target.value)}>
            <MenuItem value=''> Selecione </MenuItem>
            {
              meses.map(mes => {
                return (
                  <MenuItem key={mes.id} value={mes.id}> {mes.nome} </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </div>

      <div className='col-md-8 offset-md-2 mt40 mb80 calendarTheme'>
        <Calendar 
          value={dataSelecionada}
          onSelect={selectDate}
          dateCellRender={dateCellRender}
          locale={localization}
          mode="month"
          //validRange={validRange}
          disabledDate={disabledDate}
          headerRender={headerRender}
        />
      </div>

      <Modal open={openModal} titulo={""} close={closeModal} >
        <div className="mb20">
          <div className="col-sm-12 mb20 text-center">
            Horários disponíveis | Data: {moment(dataSelecionadaString).format('DD/MM/YYYY')} 
          </div>
          <div className="col-sm-12 flex center" style={{maxWidth: '400px'}}>
            {
              horariosDisponiveis.map((item, index) => (
                <AgendamentoHorario 
                  key={index} 
                  item={item} 
                  disabledList={vaga?.dataHorarioIndisponiveis} 
                  selectHora={selecionarHoraDia}
                  horaAtiva={horaActive}
                  data={dataSelecionadaString}
                />
              ))
            }
          </div>
          <div className="col-sm-12 mt20">
            <button className="btn btn-info btn-lg btn-block" onClick={confirmarHorario}>Confirmar Horário</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Reagendamento;