import { SNACKBAR_SUCCESS, SNACKBAR_CLEAR, SNACKBAR_WARNING, SNACKBAR_ERROR } from '../actions/actionTypes';

const initialState = {
    successSnackbarOpen: false,
    successSnackbarInfo: 'info',
    successSnackbarMessage: ``
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SNACKBAR_SUCCESS:
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarInfo: 'success',
                successSnackbarMessage: action.message
            };
        case SNACKBAR_WARNING:
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarInfo: 'warning',
                successSnackbarMessage: action.message
            };
        case SNACKBAR_ERROR:
            let message
            //console.log(typeof action.message )
            if (typeof action.message === 'string') {
                message = action.message
            } else {
                message = 'Algum erro aconteceu não foi possível realizar está ação'
            }
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarInfo: 'error',
                successSnackbarMessage: message
            };
        case SNACKBAR_CLEAR:
            return {
                ...state,
                successSnackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };
        default:
            return state;
    }
};

export default snackbarReducer;