var globals = {
  // ####  AUTH  ####
  // ####  AUTH  ####
  REACT_APP_APIADRESS: process.env.REACT_APP_APIADRESS,
  REACT_APP_MAIN_DOMAIN: process.env.REACT_APP_MAIN_DOMAIN,
  // ###########################################################################################################

  // ####  AUTO  ####
  // ####  AUTO  ####
  REACT_APP_APIROOT_AUTO: process.env.REACT_APP_APIROOT_AUTO,
  REACT_APP_RECAPTCHA_KEY_AUTO: process.env.REACT_APP_RECAPTCHA_KEY_AUTO,
  REACT_APP_AUTO_APP: process.env.REACT_APP_AUTO_APP,
  // ###########################################################################################################

  // ####  SOLAR  ####
  // ####  SOLAR  ####
  REACT_APP_APIROOT_SOLAR: process.env.REACT_APP_APIROOT_SOLAR,
  REACT_APP_RECAPTCHA_KEY_SOLAR: process.env.REACT_APP_RECAPTCHA_KEY_SOLAR,
  REACT_APP_SOLAR_APP: process.env.REACT_APP_SOLAR_APP,
  // ###########################################################################################################
};
export default globals;
