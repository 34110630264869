
import { SNACKBAR_SUCCESS, SNACKBAR_WARNING, SNACKBAR_ERROR, SNACKBAR_CLEAR } from './actionTypes';

export const showMsgS = message => ({
    type: SNACKBAR_SUCCESS,
    message: message
});

export const showMsgW = message => ({
    type: SNACKBAR_WARNING,
    message: message
});

export const showMsgE = message => ({
    type: SNACKBAR_ERROR,
    message: message
}); 

export const showMsgC = () => ({
    type: SNACKBAR_CLEAR
});
