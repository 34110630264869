import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import DefaultRoutes from '../layouts/Defaultroutes';
import PrivateRoutes from '../layouts/Privateroutes';
import PrivateRoutesEmpresa from '../layouts/PrivateroutesEmpresa';
// import FullPageRoutes from '../layouts/Fullpageroutes';
import Notfound from '../views/NotFound/NotFound';
import Login from '../views/Login/index';
import EsqueciSenha from '../views/EsqueciSenha';

import MeuCadastro from '../views/Cadastro/MeuCadastro/index';
import VagaDetalhe from '../views/Vagas/VagaDetalhe/index';

import Agenda from '../views/Agenda/index';
import FaleConosco from '../views/FaleConosco/Lista/index';
import FaleConoscoDetalhe from '../views/FaleConosco/Detalhe/index';
import FaleConoscoNovo from '../views/FaleConosco/Novo/index';
import Candidaturas from '../views/Candidaturas/Lista/index';
import CandidaturasDetalhe from '../views/Candidaturas/Detalhe/index';
import MeuCadastroEmpresa from '../views/Cadastro/MeuCadastroEmpresa/index';

import Home from '../views/Home/index'

export default  () => (
    <BrowserRouter>
        <Switch>
            <Route path='/' exact component={Login} />
            <Route path='/home' exact component={Login} />
            <Route path='/esqueci-senha' exact component={EsqueciSenha} />
            <Route path='/empty' exact component={Notfound} />
            {/*<DefaultRoutes path='/home' exact component={Home} />*/}
{/*
            <PrivateRoutes path='/meu-cadastro' exact component={MeuCadastro} />
            <PrivateRoutes path='/home' exact component={Home} />
            
            <PrivateRoutesEmpresa path='/home' exact component={Home} />
            <PrivateRoutesEmpresa path='/vaga/:id' exact component={VagaDetalhe} />
            <PrivateRoutesEmpresa path='/meu-cadastro-empresa' exact component={MeuCadastroEmpresa} />
            <PrivateRoutesEmpresa path='/candidaturas' exact component={Candidaturas} />
            <PrivateRoutesEmpresa path='/candidaturas/:id' exact component={CandidaturasDetalhe} />
            <PrivateRoutesEmpresa path='/agenda' exact component={Agenda} />
            <PrivateRoutesEmpresa path='/fale-conosco' exact component={FaleConosco} />
            <PrivateRoutesEmpresa path='/fale-conosco-novo' exact component={FaleConoscoNovo} />
            <PrivateRoutesEmpresa path='/fale-conosco/:id' exact component={FaleConoscoDetalhe} />
*/}
            {/* <DefaultRoutes component={Notfound} /> */}
        </Switch>
    </BrowserRouter>
);
