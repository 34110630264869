import React, { useEffect } from 'react';
import moment from 'moment'
import { Calendar, Badge } from 'antd';
import { trackPromise } from 'react-promise-tracker';

import services from '../../services';

/////////////////////////////
// redux
import { useDispatch } from "react-redux"
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './styles.scss'

// import Modal from '../../components/Modal/Modal';
import AgendamentoItemDestaque from '../../components/Evento/AgendamentoItemDestaque';
import AgendamentoItemCalendar from '../../components/Evento/AgendamentoItemCalendar';
import AgendamentoHeaderDestaque from '../../components/Evento/AgendamentoHeaderDestaque';
import VagaInfosReadOnly from '../../components/Vaga/VagaInfosReadOnly';
import CandidatoReadOnly from '../../components/Vaga/CandidatoReadOnly';

import { useGetMeses } from '../../hooks/useGetMeses';
import { useGetAnos } from '../../hooks/useGetAnos';

moment.updateLocale('pt', {weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]});

const currentDay = new Date().getDate()
const localization = {
  "lang": {
    "locale": "pt_BR",
    "placeholder": "Selecionar Data",
    "rangePlaceholder": ["Data Inicio", "Data Fim"],
    "today": "Hoje",
    "now": "Agora",
    "backToToday": "Voltar para hoje",
    "ok": "Ok",
    "clear": "Limpar",
    "month": "Mês",
    "year": "Ano",
    "timeSelect": "Selecionar Tempo",
    "dateSelect": "Selecionar Data",
    "monthSelect": "Escolha um mês",
    "yearSelect": "Escolha um ano",
    "decadeSelect": "Escolha uma decada",
    "yearFormat": "YYYY",
    "dateFormat": "M/D/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
    "previousMonth": "Mês anterior (PageUp)",
    "nextMonth": "Próximo mês (PageDown)",
    "previousYear": "Ano Passado (Control + left)",
    "nextYear": "Próximo ano (Control + right)",
    "previousDecade": "Decada passada",
    "nextDecade": "Próxima decada",
    "previousCentury": "Próximo seculo",
    "nextCentury": "Seculo passado",
  },
  "timePickerLocale": {
    "placeholder": "Selecionar tempo"
  },
  "dateFormat": "YYYY-MM-DD",
  "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
  "weekFormat": "YYYY-wo",
  "monthFormat": "YYYY-MM"
}

// const mockAgendamento = {
//   agendamentoData: "2021-06-24T14:48:00.000Z",
//   agendamentoHora: "14:00",
// }
// const mockDatasDesativadas = [
//   "2021-06-25T14:48:00.000Z", "2021-06-28T14:48:00.000Z"
// ]

const detalheAgendamentoInit = {
  "candidatoVagaId":"",
  "nivelAvaliacao": '',
  "status":"",
  "candidato":{
    "candidatoId":"",
    "nome":"",
    "cpf":"",
    "email":"",
    "urlPdf":null,
    "urlLinkedIn":null
  },
  "vaga": {
    "vagaId":"",
    "codigoVaga":"",
    "dataAnuncio":"",
    "dataDisponivelAte":"",
    "recrutador":"",
    "empresa":"",
    "titulo":"",
    "perfilDaEmpresa":"",
    "requisitosTecnicos":"",
    "requisitosComportamentais":"",
    "tipoContratacao":"PJ",
    "outroTipoContratacao":"",
    "modeloTrabalho":"",
    "mobilidadeCandidato":"",
    "nivelHierarquicoVaga":"",
    "faixaSalarial":"",
    "beneficios":"",
    "segmentosBusca":"",
    "cidade":"",
    "uf":"",
    "bairro":"",
    "tipoAnuncio":"",
    "preferencias":"",
    "statusProcessoVaga":"",
    "conteParaNos":"",
    "urlQuestionario":"",
    "idiomas":[],
    "dataHorarioIndisponiveis":null,
    "dataHorarioAgendadaCandidato":null
  }
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0
  },
});


const AgendarEntrevista = ({formPage, changes}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const meses = useGetMeses();
  const anos = useGetAnos();

  const [dataSelecionadaHeader, setDataSelecionadaHeader] = React.useState('');
  const [horariosAgenda, setHorariosAgenda] = React.useState([]);
  
  const [dataSelecionada, setDataSelecionada] = React.useState(moment(`${formPage.ano}-${formPage.mes + 1}-${currentDay}`));
  const [agendamento, setAgendamento] = React.useState([]);
  const [disabledDates, setDisabledDates] = React.useState([]);
  const [candidatoDetalheAgendamento, setCandidatoDetalheAgendamento] = React.useState({...detalheAgendamentoInit});

  // const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = () => { setOpenModal(true) };
  // const closeModal = () => { setOpenModal(false) };

  const selectDate = (event) => {
    setDataSelecionada(event)
    //handleOpenModal()
  }

  // function onPanelChange(value, mode) {
  //   console.log('canda ano')
  //   console.log(value.format('YYYY-MM-DD'), mode);
  // }

  function getListData(value) {
    let calendarDay = value.toISOString().substring(0,10)
    // console.log('oioioi', value.toISOString())
    let listData;
    let eventosDia = agendamento.filter(x => calendarDay === x.dataAgenda.substring(0,10))

    if (eventosDia.length > 0) {
      //let getDayAgendamento = agendamento.agendamentoData.substring(8,9) === '0' ? agendamento.agendamentoData.substring(9,10) : agendamento.agendamentoData.substring(8,10)
      let getDayAgendamento = eventosDia[0].dataAgenda.substring(0,10)
      if (calendarDay === getDayAgendamento) {
        listData = [
          { 
            content: eventosDia[0].quantidadeReunioes
          }
        ];
      }
    }
    return listData || [];
  }

  function disabledDate(date) {
    let calendarDay = date.toISOString().substring(0,10)
    //console.log('current Date', calendarDay)

    let start = '2021-06-10';
    let end = '2021-06-30';
    if (date < moment(start)){
      return true;
    }
    else if (date > moment(end)){
      return true;
    }
    for(let x = 0; x < disabledDates.length; x++) {
      if (calendarDay === ( disabledDates[x].substring(0,8) + (parseInt(disabledDates[x].substring(8,10)) + 1 ))) {
        return true;
      }
    }
    return false
  }

  function validRange(date) {
    return [moment("2021-10-07"), moment("2021-10-09")]
  }

  function headerRender(e) {
    // return (
    //   <div className="p-1"> <button type="link">Today</button> </div>
    // )
    return null
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <AgendamentoItemCalendar 
        listData={listData} 
        action={getAgendaDetalhe} 
        date={value} 
      />
    );
  }

  const getAgenda = (ano, mes) => {
    setCandidatoDetalheAgendamento({...detalheAgendamentoInit})

    //console.log(ano, mes, currentDay)
    setDataSelecionada(moment(`${ano}-${mes + 1}-${currentDay}`))
    //setDataSelecionada(moment(`${ano}-${mes + 1}-01`))
    //setDataSelecionada(moment(`${ano}-2-30`))

    trackPromise (
      services.api.recrutadorAgenda(ano, mes + 1)
      .then(res => {
        if (!res.fail) {
          // setAgendamento(
          //   [
          //     {
          //       dataAgenda: "2021-08-30T00:00:00",
          //       quantidadeReunioes: 1
          //     }
          //   ]
          // )
          setAgendamento(res.data)
        }
      }).catch(error => { })
    )
  }

  const getAgendaDetalhe = (dataEvent) => {
    setCandidatoDetalheAgendamento({...detalheAgendamentoInit})
    // console.log('--------', dataEvent)
    let dateString = ''
    if (dataEvent) {
      let dateIso = dataEvent.toISOString();
      if (dateIso) dateString = dateIso.substring(0,10);
    }
    trackPromise (
      services.api.recrutadorAgendaDetalhe(dateString)
      .then(res => {
        if (!res.fail) {
          setDataSelecionadaHeader(res.data.data)
          setHorariosAgenda(res.data.horariosAgenda)
        }
      }).catch(error => { })
    )
  }

  const getCandidatoDetalhe = (candidatoId) => {
    setCandidatoDetalheAgendamento({...detalheAgendamentoInit})
    trackPromise (
      services.api.candidatoDetalheAgenda(candidatoId)
      .then(res => {
        if (!res.fail) {
          setCandidatoDetalheAgendamento(res.data)
        }
      }).catch(error => { })
    )
  }

  const reEnviarMail = () => {
    trackPromise (
      services.api.reEnviarMail(candidatoDetalheAgendamento.candidatoVagaId)
      .then(res => {
        if (!res.fail) {
          showMsgW('Email enviado com sucesso')
        } else {
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => { 
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }
  
  useEffect(() => {
    getAgenda(formPage.ano, formPage.mes)
  }, [formPage.ano, formPage.mes]);

  return (
    <div className="">
      <div className="container">
        <div className="row">
          <div className='col-md-8'>
            <div className="row">
              <div className='col-md-6'>
                <FormControl>
                  <InputLabel>Ano</InputLabel>
                  <Select
                    value={formPage.ano}
                    onChange={event => changes(event.target.value, 'ano')}
                  >
                    {
                      anos.map(ano => {
                        return (
                          <MenuItem key={ano.nome} value={ano.nome} > {ano.nome} </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <FormControl>
              <InputLabel>Mês {formPage.mes}</InputLabel>
              <Select
                value={formPage.mes}
                onChange={event => changes(event.target.value, 'mes')}
              >
                <MenuItem value=''> Selecione </MenuItem>
                {
                  meses.map(mes => {
                    return (
                      <MenuItem key={mes.id} value={mes.id}> {mes.nome} </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row">

          <div className='col-md-8 mt40 mb80 calendarTheme'>
            <Calendar 
              value={dataSelecionada}
              onSelect={selectDate}
              dateCellRender={dateCellRender}
              locale={localization}
              mode="month"
              //validRange={validRange}
              //disabledDate={disabledDate}
              headerRender={headerRender}
            />
          </div>

          <div className='col-md-4 mt40 mb80'>
            {
              dataSelecionadaHeader ?
              <>
              <AgendamentoHeaderDestaque content={dataSelecionadaHeader} />
              <div>
                {
                  horariosAgenda.map((hrAgenda, index) => {
                    return (
                      <AgendamentoItemDestaque key={index} 
                        action={getCandidatoDetalhe} content={hrAgenda}
                      />
                    )
                  })
                }
                {
                  horariosAgenda.length === 0 && dataSelecionadaHeader &&
                  'Não ha agendamentos nesta data'
                }
              </div>
              </>
              :
              <AgendamentoHeaderDestaque content={'Selecione uma data'} />
            }
          </div>
        </div>
      </div>

      {
        candidatoDetalheAgendamento?.candidatoVagaId &&
        <div className="containerBgWhite faixaTopDivider">

          <div className="container">
            <div className="row">
              <div className='col-md-12 text-center'>
                  <h3>Dados do <strong>Candidato</strong></h3>
              </div>
            </div>
          </div>
          <CandidatoReadOnly candidato={candidatoDetalheAgendamento.candidato} />
          <div className="container mt20 mb80">
            <div className="row">
              <div className='col-md-12 text-center'>
                <button className="btn btn-info btn-lg" onClick={reEnviarMail}>
                  Enviar E-mail
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className='col-md-12 text-center'>
                  <h3>Dados da <strong>Vaga</strong></h3>
              </div>
            </div>
          </div>
          
          <VagaInfosReadOnly vaga={candidatoDetalheAgendamento.vaga} />

        </div>
      }
    
      {/* <Modal open={openModal} titulo={"Horários disponíveis"} close={closeModal} >
        <div className=""> 
          <div className="row mb20">
            <div className="col-sm-12">
              Data: xxx
            </div>

            <div className="col-sm-12">
              Horários
            </div>
              
            <div className="col-sm-12 mt20">
              <button className="btn btn-info btn-lg btn-block">Agendar</button>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default AgendarEntrevista;