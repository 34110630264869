import React, { useState, useEffect } from 'react';
import Modal from '../../../components/Modal/Modal';


const PopUpConfirmarSolar = ({openModalCPFNaoEncontrado,closeModalCPF,procurarParceiroSolar,windowDimensions}) => {


    return(
      <Modal 
          open={openModalCPFNaoEncontrado} close={closeModalCPF} size="lg"
        >
          <div className="pad40">
            <center>
              <h2 className="mb40"><strong>Atenção </strong></h2>
            </center>
            <center>
              <span>Sua inscrição será no segmento Solar. Deseja continuar?</span>
            </center>
            <br/>
            <div className="preCadSquare" >
              <div className="preCadLinha2"  >
                {
                  (windowDimensions.width>375)?
                  <center>
                    <table>
                      <tr>
                        <td>
                          <button className="btn btn-dark btn-block btn-lg botPreCadastro " onClick={procurarParceiroSolar} >
                            SIM
                          </button>
                        </td>
                        <td>
                          <button className="btn btn-dark btn-block btn-lg  botPreCadastro ml10" onClick={closeModalCPF} >
                            NÃO
                          </button>
                        </td>
                      </tr>
                    </table>
                  </center>:
                  <center  >
                        <button className="btn btn-dark btn-block btn-lg  " onClick={procurarParceiroSolar} >
                          SIM
                        </button>
                        <button className="btn btn-dark btn-block btn-lg " onClick={closeModalCPF} >
                          NÃO
                        </button>
                  </center>
                }
                
              </div>
            </div>
           
            <br/><br/>
            <center>
              <span>Se você é do segmento Auto e está visualizando essa mensagem.<br/>entre em contato com o seu Gerente de Relacionamento antes de prosseguir.</span>
            </center>
            
          </div>
            
        </Modal>
    )
}

export default PopUpConfirmarSolar;

