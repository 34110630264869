import React, { useState, useRef } from 'react';

import '../styles.scss';
import './styles.scss';
import services from '../../../services';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import {
  //showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  //showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';
import Modal from '../../../components/Modal/Modal';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import globals from '../../../globals';
import DOMPurify from 'dompurify';
import { validaEmail } from '../../../services/validaEmail.js';

const purificaDom = (event) => {
  const limpo = DOMPurify.sanitize(event);
  return limpo;
};

const RECAPTCHA_KEY_AUTO = globals.REACT_APP_RECAPTCHA_KEY_AUTO;
const RECAPTCHA_KEY_SOLAR = globals.REACT_APP_RECAPTCHA_KEY_SOLAR;

const FormRecuperarSenha = ({ modalStatus, setModalStatus }) => {
  const recaptcha_Auto = useRef(null);
  const recaptcha_Solar = useRef(null);
  const dispatch = useDispatch();
  //const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  //const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [email, setEmail] = useState('');

  const concluir = () => {
    setModalStatus({
      ...modalStatus,
      esqueciSenha: false,
      conclusaoEsqueciSenha: true,
    });
  };

  const voltarHome = () => {
    setModalStatus({
      ...modalStatus,
      esqueciSenha: false,
    });
  };

  const recuperarSenha = () => {
    if (email === null) {
      return;
    }
    sendAuto();
  };

  const sendAuto = () => {
    if (recaptcha_Auto.current) {
      trackPromise(
        recaptcha_Auto.current.executeAsync().then((recaptchaToken) => {
          services.storage.set('captcha', recaptchaToken);
          services.apiAuto
            .resetarSenha({ email: email })
            .then((res) => {
              //console.log(res.errors.message);
              if (!res.fail) {
                concluir();
              } else {
                sendSolar(res);
              }
            })
            .catch((error) => {
              sendSolar();
            });
        })
      );
    }
  };

  const sendSolar = (resAuto) => {
    if (recaptcha_Solar.current) {
      trackPromise(
        recaptcha_Solar.current
          .executeAsync()
          .then((recaptchaToken) => {
            services.storage.set('captcha', recaptchaToken);
            services.apiSolar
              .resetarSenha({ email: email })
              .then((res) => {
                // console.log(res.errors.length);
                // console.log(res.errors.message);
                if (!res.fail) {
                  concluir();
                } else {
                  showMsgE(
                    resAuto.errors.message.length
                      ? resAuto.errors.message
                      : 'Error'
                  );
                  voltarHome();
                }
              })
              .catch((error) => {
                voltarHome();
                showMsgE(
                  resAuto.errors.message.length
                    ? resAuto.errors.message
                    : 'Error'
                );
              });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
  };

  return (
    <Modal
      open={modalStatus.esqueciSenha}
      close={() => {
        setModalStatus({ ...modalStatus, esqueciSenha: false });
      }}
      size="lg"
    >
      <div className="pageBody FormRecuperarSenha">
        <div className="container-fluid">
          <h2>Informe seu e-mail cadastrado para receber a nova senha</h2>
          <FormControl>
            <TextField
              label="E-mail"
              type="text"
              className="inputCustom"
              size="small"
              value={email}
              onChange={(event) => setEmail(purificaDom(event.target.value))}
              variant="filled"
            />
            <div className="avisoCampo">
              {!validaEmail(email)
                ? email.length > 5
                  ? 'Inserir e-mail valido'
                  : ''
                : ' '}
            </div>
          </FormControl>
          <Recaptcha
            ref={recaptcha_Auto}
            sitekey={RECAPTCHA_KEY_AUTO}
            size="invisible"
            render="explicit"
          />
          <Recaptcha
            ref={recaptcha_Solar}
            sitekey={RECAPTCHA_KEY_SOLAR}
            size="invisible"
            render="explicit"
          />
          <button
            className="btn btn-dark btn-block btn-lg mt20 buttonCustom"
            onClick={recuperarSenha}
            disabled={!validaEmail(email)}
          >
            Resetar
          </button>
        </div>

        <div className="container-fluid">
          <hr />
          <p>Em caso de dúvidas,</p>
          <p>
            Parceiro de Solar e de Veículos, envie um e-mail para{' '}
            <a href="mailto:bvrelacionar@ltmfidelidade.com.br?subject=Troca de E-mail&amp;body=Prezados, solicito alteração do e-mail de cadastro.%0D%0A%0D%0AInformações do Cadastro%0D%0ANome Completo:%0D%0ACPF:%0D%0AE-mail Antigo:%0D%0AE-mail Novo:%0D%0ACódigo da Loja:%0D%0ACNPJ da Loja:">
              bvrelacionar@ltmfidelidade.com.br
            </a>
          </p>
          {/* <p>
            Parceiro de Veículos, acesse o Atendimento Whatsapp (
            <a
              href="https://wa.me/551123946370"
              target="_blank"
            >
              Clique Aqui
            </a>
            )
          </p> */}
        </div>
      </div>
    </Modal>
  );
};

export default FormRecuperarSenha;
