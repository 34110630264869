import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import './styles.scss'

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
        padding: '26px 0 0 0',
        listStyle: 'none',
        fontSize: '11px',
        border: '4px solid #7038FF',

        '& li': {
            border: "1px solid #7038FF",
            color: "#7038FF",
            margin: "0",
            borderRadius: "20px",
            
            fontWeight: 'bold',
            height: "30px",
            padding: "2px 6px",

            '& span': {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap"
            }
        }
    },
    rootNoStyle: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
        padding: '26px 0 0 0',
        listStyle: 'none',
        fontSize: '11px',
    },
});

export default function AgendamentoItemCalendarFull({listData, action, date, exclude}) {
    const classes = useStyles();
    return (
        <>
        { listData.length > 0 ?
            <ul className={classes.root}>
                {listData.map((item) => (
                    <li key={item.content}>
                        <span>
                            {item.content} <CloseIcon onClick={exclude} />
                        </span>
                    </li>
                ))}
            </ul>
            :
            <ul className={classes.rootNoStyle} onClick={(e) => action(date)}></ul>
        } 
        </>
    )
}