import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from "react-redux";

import { 
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';

import './styles.scss';

import services from '../../services';

import FormLogin from './FormLogin'
import FormExisteParceiro from './FormExisteParceiro';
import FormCodigoParceiroAuto from './FormCodigoParceiroAuto';
import FormCompletarCadastroAuto from './FormCompletarCadastroAuto';
import FormCompletarCadastroSolar from './FormCompletarCadastroSolar';
import FormConclusaoPreCadastro from './FormConclusaoPreCadastro';
import FormRecuperarSenha from './FormRecuperarSenha';
import ConclusaoRecuperarSenha from './ConclusaoRecuperarSenha';
import PopUpConfirmarSolar from './PopUpConfirmarSolar/PopUpConfirmarSolar'; 
import footerCompany from '../../assets/img/footer.png';

const formExisteParceiroInitialState = {
  documento: '',
  codigo: '',
  idParceiro: '',
}

const modalInitialStatus = {
  esqueciSenha: false,
  primeiroAcesso: false,
  codigoParceiroAuto: false,
  completarCadastroAuto: false,
  completarCadastroSolar: false,
  conclusaoPreCadastro: false,
  conclusaoEsqueciSenha: false
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const Login = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));

  const [formParceiro, setFormParceiro] = React.useState({...formExisteParceiroInitialState});
  const [modalStatus, setModalStatus] = useState(modalInitialStatus);
  const [openModalCPFNaoEncontrado,setOpenModalCPFNaoEncontrado] = useState(false);
  const [formatObtCompartilhado,setFormatObtCompartilhado] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [validateCNPJ,setValidateCNPJ] = useState(false);

  const closeModalCPF = () => {
     setOpenModalCPFNaoEncontrado(false);
     setModalStatus(false);
     setValidateCNPJ(false);
     setFormParceiro({
      ...formParceiro,
      documento: ''
    })
  }
  
  useEffect(()=>{
    console.log(formParceiro);
    console.log('recalcular process');
    console.log('the effect');
      console.log(windowDimensions);
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  
  },[formParceiro]);

  useEffect(()=>{
    console.log('recalcular process-2');
    console.log('the effect');
      console.log(windowDimensions);
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  
  },[openModalCPFNaoEncontrado]);

  const continuarSolar = () => {
    setModalStatus({
      ...modalStatus,
      primeiroAcesso: false,
      completarCadastroSolar: true,
    })
  }

  const procurarParceiroSolar = () => {
    var formatObt = formatObtCompartilhado;
    //alert('procurarParceiroSolar');
    //alert(JSON.stringify(formatObtCompartilhado));
    trackPromise (
      services.apiSolar.existeParceiroNaBase(formatObt).then(res => {

        if (!res.fail) {
          if (res.data == null)
          {
            setFormParceiro({
              ...formParceiro,
              cnpj: '',
              idParceiro: '',
            })
          }
          else
          {
            setFormParceiro({
              ...formParceiro,
              idParceiro: res.data.parceiroId,
            })
          }
        } else {
          setFormParceiro({
            ...formParceiro,
            cnpj: '',
            idParceiro: '',
          })
        }

        continuarSolar();
      }).catch(error => {
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }

  useEffect(() => {
    async function init() {
      let isAuthenticated = services.storage.get('isAuthenticated')
      let isUserCompany = services.storage.get('isUserCompany')
      if (isAuthenticated && !isUserCompany) {
        //history.push(`/minhas-vagas`)
        history.push(`/home`)
      }
      if (isAuthenticated && isUserCompany) {
        history.push(`/home`)
      }
    }
    init()
  }, [])
  
  return (
    <div>
 
      <div className='container-fluid loginPage'>
        <div className='row'>
          <div className='col-md-6 offset-md-6 boxLogin'>
            <FormLogin
              modalSenha={ () => {  setModalStatus({...modalStatus, esqueciSenha: true}); }}
              modalPrimeiroAcesso={ () => { setValidateCNPJ(false); setModalStatus({...modalStatus, primeiroAcesso: true}); }}
            />
          </div>
        </div>

        <FormExisteParceiro 
          formParceiro={formParceiro}
          setFormParceiro={setFormParceiro}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          setFormatObtCompartilhado={setFormatObtCompartilhado}
          procurarParceiroSolar={procurarParceiroSolar}
          setOpenModalCPFNaoEncontrado={setOpenModalCPFNaoEncontrado}
          validateCNPJ={validateCNPJ}
          setValidateCNPJ={setValidateCNPJ}
        />

        <FormCodigoParceiroAuto 
          formParceiro={formParceiro}
          setFormParceiro={setFormParceiro}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
        
        <FormConclusaoPreCadastro 
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />

        <FormCompletarCadastroAuto 
          formParceiro={formParceiro}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />

        <FormCompletarCadastroSolar 
          formParceiro={formParceiro}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />

        <FormRecuperarSenha 
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />

        <ConclusaoRecuperarSenha 
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />

        {/* novo pop-up de confirmação */}
        <PopUpConfirmarSolar
          openModalCPFNaoEncontrado={openModalCPFNaoEncontrado}
          closeModalCPF={closeModalCPF}
          procurarParceiroSolar={procurarParceiroSolar}
          windowDimensions={windowDimensions}
        />
    
      </div>
      
      <div className="container-fluid footerPage"> 
        <div className="row footerPage">
            <div className="col-md-12 footerPage">  
              <div> 
                  <img src={footerCompany} alt=" " />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
  
}

export default Login;
