import React, { useEffect } from 'react';

import './styles.scss';

import HeaderPage from './HeaderPage';
import AgendarEntrevista from './AgendarEntrevista';

const initform = {
  ano: new Date().getFullYear(),
  mes: new Date().getMonth()
}

const Agenda = props => {
  const [formPage, setFormPage] = React.useState({...initform});

  const handleChangeForm = (value, campo) => {
    setFormPage( prevstate => ({...prevstate, [campo]: value }) )
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <HeaderPage />

      <AgendarEntrevista formPage={formPage} changes={handleChangeForm} />

      {/* <DadosCandidato /> */}
    </>
  );
  
}

export default Agenda;