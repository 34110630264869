export function validaSenha(senha) {
  const validaSenha = [false, false, false, false];

  if (senha.length >= 8) {
    validaSenha[0] = true;
  } else {
    validaSenha[0] = false;
  }

  if (senha.replace(/[^0-9]/g, "").length > 0) {
    validaSenha[1] = true;
  } else {
    validaSenha[1] = false;
  }

  if (
    senha.replace(/[^a-z]/g, "").length > 0 &&
    senha.replace(/[^A-Z]/g, "").length > 0
  ) {
    validaSenha[2] = true;
  } else {
    validaSenha[2] = false;
  }

  if (senha.replace(/[a-zA-Z0-9]/g, "").length > 0) {
    validaSenha[3] = true;
  } else {
    validaSenha[3] = false;
  }

  var num = 0;
  for (var x = 0; x <= validaSenha.length; x++) {
    if (validaSenha[x] == true) num++;
  }
  return num === 4 ? true : false;
}
