import React from 'react';

import '../styles.scss';
import Modal from '../../../components/Modal/Modal';

const FormConclusaoPreCadastro = ({ modalStatus, setModalStatus }) => {
  const voltarHome = () => {
    setModalStatus({
      ...modalStatus,
      conclusaoPreCadastro: false,
    });
    window.location.reload();
  };

  return (
    <Modal
      open={modalStatus.conclusaoPreCadastro}
      close={() => {
        setModalStatus({ ...modalStatus, conclusaoPreCadastro: false });
      }}
      size="lg"
    >
      <div className="pageBody">
        <div className="container-fluid existeParceiroPage">
          <div className="row">
            <div className="col text-left">
              <h1>
                <b>Cadastro concluído</b>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col text-left">
              {/* <h5>Seus dados foram encaminhados para análise.</h5> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 padB20">
              <button
                className="btn btnVoltar btn-lg btn-block"
                onClick={voltarHome}
              >
                Ir para o login
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormConclusaoPreCadastro;
