const _difference = async (a1, a2) => {
    let a2Set = new Set(a2);
    return a1.filter(function (x) { return !a2Set.has(x); });
}

// retorna elementos diferentes entre dois arrays
export const arrayDiference = async (arrayA, arrayB) => {
    return _difference(arrayA, arrayB).concat(_difference(arrayB, arrayA))
}

// retorna elementos iguais entre dois arrays
export const arrayInterseccao = async (arrayA, arrayB) => {
    return arrayA.filter(x => arrayB.includes(x))
}

// converte data
export const convertDateIsoToDate = async (date, format = false) => {
    let newDate = ''
    if(format === false) {
        newDate = new Date(
            date.substring(0,4), 
            date.substring(5,7) -1, 
            date.substring(8,10),
            date.substring(11,13),
            date.substring(14,16),
            date.substring(17,19)
        )
    } else {
        newDate = `${date.substring(8,10)}/${date.substring(5,7)}/${date.substring(0,4)}`
    }
    return newDate
}


