import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Recaptcha from "react-google-recaptcha";

import "./styles.scss";
import services from "../../services";

import logoCompany from "../../assets/img/companyLogo.png";
import logoTopoCompany from "../../assets/img/companyLogoTopo.png";
import { useDispatch } from "react-redux";
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  //showMsgS as showMsgSAction,
} from "../../store/actions/snackbarActions.js";
import RegulamentoAceite from "./FomRegulamentoAceite";
import Modal from "../../components/Modal/Modal";
import FormRegulamentoAuto from "../Login/FormRegulamentoAuto";
import globals from "../../globals";
import { validaCpf } from "../../services/validaCpf.js";
import { validaSenha } from "../../services/validaSenha.js";

import DOMPurify from "dompurify";
const purificaDom = (event) => {
  const limpo = DOMPurify.sanitize(event);
  return limpo;
};

const RECAPTCHA_KEY_AUTO = globals.REACT_APP_RECAPTCHA_KEY_AUTO;
const RECAPTCHA_KEY_SOLAR = globals.REACT_APP_RECAPTCHA_KEY_SOLAR;
//const formLoginInitialState = { login: '24584500592', senha: 'Teste@01' };
const formLoginInitialState = { login: "", senha: "" };

const formRegulamentoAceiteInitialState = {
  userId: "",
  regulamentoId: "",
  aceiteRegulamento: false,
};

const FormLogin = ({ modalSenha, modalPrimeiroAcesso }) => {
  const history = useHistory();
  const recaptcha_Auto = useRef(null);
  const recaptcha_Solar = useRef(null);
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));

  const formRef = useRef(null);
  const inputRef = useRef(null);

  const [formLogin, setFormLogin] = useState({ ...formLoginInitialState });
  const [indicarErrosLogin, setIndicarErrosLogin] = useState(false);
  const [formLogar, setformLogar] = React.useState(null);
  const [
    openModalRegulamentoAceite,
    setOpenModalRegulamentoAceite,
  ] = React.useState(false);
  const [regulamentoUrl, setregulamentoUrl] = React.useState("");
  const [regulamentoVigente, setregulamentoVigente] = React.useState("");
  const [formRegulamentoAceite, setFormRegulamentAceite] = React.useState({
    ...formRegulamentoAceiteInitialState,
  });
  const [
    indicarErrosRegulamentoAceite,
    setIndicarErrosRegulamentoAceite,
  ] = React.useState(false);
  const [openModalRegulamento, setOpenModalRegulamento] = React.useState(false);

  const logar = () => {
    if (!formLogin.login || !formLogin.senha) {
      setIndicarErrosLogin(true);
      showMsgW("Informe os campos obrigatórios");
      return false;
    }
    if (!services.validate.valideCpf(services.mask.unMask(formLogin.login))) {
      showMsgW("Informe um cpf válido");
      return false;
    }

    logarAuto();
  };

  const logarAuto = () => {
    trackPromise(
      services.apiAuto
        .logar({
          userName: services.mask.unMask(formLogin.login),
          password: formLogin.senha,
        })
        .then((res) => {
          if (res.errors?.statusCode == 400) {
            showMsgE(res.errors?.message);
            return;
          }

          if (res.fail) {
            logarSolar();
            return;
          }

          setformLogar(res);
          buscarRegulamentoVigentePorUsuario(res);
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : "Error");
        })
    );
  };

  const logarSolar = () => {
    if (recaptcha_Solar.current) {
      trackPromise(
        recaptcha_Solar.current
          .executeAsync()
          .then((recaptchaToken) => {
            services.storage.set("captcha", recaptchaToken);
            services.apiSolar
              .logar({
                userName: services.mask.unMask(formLogin.login),
                password: formLogin.senha,
              })
              .then((res) => {
                if (res.fail) {
                  showMsgE(res.errors.message);
                  return;
                }


                formRef.current.action = `${globals.REACT_APP_SOLAR_APP}auth/default.aspx`;
                inputRef.current.value = `${res.data.token}`
                formRef.current.submit();

              })
              .catch((error) => {
                showMsgE(
                  error.errors.length ? error.errors[0].message : "Error"
                );
              });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
  };

  const closeModalRegulamento = () => {
    setOpenModalRegulamento(false);
  };

  const closeModalRegulamentoAceite = () => {
    setOpenModalRegulamentoAceite(false);
  };

  const buscarRegulamentoVigentePorUsuario = (resLogin) => {
    console.log("resLogin", resLogin);
    let user = resLogin.data.profile.userId;
    trackPromise(
      services.apiAuto
        .buscarRegulamentoVigentePorUsuario(user)
        .then((res) => {
          if (!res.fail) {
            if (res.data != null) {
              setOpenModalRegulamentoAceite(true);
              setregulamentoUrl(res.data.linkPdf);
              setregulamentoVigente(res.data.regulamentoId);
              setFormRegulamentAceite((userId) => ({
                ...userId,
                ["userId"]: user,
              }));
              setFormRegulamentAceite((regulamentoId) => ({
                ...regulamentoId,
                ["regulamentoId"]: res.data.regulamentoId,
              }));
            } else {
              direcionamentos(resLogin);
            }
          }
        })
        .catch((error) => {})
    );
  };

  const abrirRegulamentoUsuario = () => {
    setOpenModalRegulamento(true);
  };

  const avancar = (event, campo) => {
    if (!formRegulamentoAceite.aceiteRegulamento) {
      setIndicarErrosRegulamentoAceite(true);
      showMsgW("Necessário aceitar o regulamento para logar.");
      return false;
    }

    trackPromise(
      services.apiAuto
        .aceiteRegulamento(
          formRegulamentoAceite.userId,
          formRegulamentoAceite.regulamentoId
        )
        .then((res) => {
          if (!res.fail) {
            closeModalRegulamentoAceite();
            direcionamentos();
          } else {
            showMsgE(res.errors.message != null ? res.errors.message : "Error");
          }
        })
        .catch((error) => {
          showMsgW("Erro ao salvar aceite de regulamento");
          showMsgE(error.errors.length ? error.errors[0].message : "Error");
        })
    );
  };

  const direcionamentos = (res) => {
    if (!res) res = formLogar;

    if (res.data.profile.role.displayName !== "Administrador") {

      if (res.data?.profile?.role?.displayName === "Recrutador") {

        formRef.current.action = `${globals.REACT_APP_AUTO_APP}auth/default.aspx`;
        inputRef.current.value = `${res.data.token}`
        formRef.current.submit();   

      } 
      else {
     
        formRef.current.action = `${globals.REACT_APP_AUTO_APP}auth/default.aspx`;
        inputRef.current.value = `${res.data.token}`
        formRef.current.submit();

      }
    } else {
      console.log(res);
      showMsgE(res.errors ? res.errors.message : "Erro");
    }
  };

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const changes = (value, campo) => {
    setFormLogin((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  if (formLogin.login != null) {
    formLogin.login = cpfMask(formLogin.login);
  }

  useEffect(() => {
    async function init() {}
    init();
  }, []);

  return (
    <div className="limitContainer">
      <div className="row">
        <div className="col-sm-12">
          <div className="containerLogoTopoImage">
            <img src={logoTopoCompany} alt=" " />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 padB120">
          <div className="containerLogoImage">
            <img src={logoCompany} alt=" " />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 padB20">
          <div className="input-container">
            <form autoComplete="off">
              <input
                id="cpf"
                className="input inputForm form-control form-control-lg"
                onChange={(event) =>
                  changes(purificaDom(event.target.value), "login")
                }
                value={formLogin.login}
                type="text"
                pattern=".+"
                required
                name="username"
              />
              <label className="label" htmlFor="cpf">
                CPF
              </label>
              <div className="avisoCampo">
                {!validaCpf(formLogin.login) && formLogin.login.length == 14
                  ? "Inserir CPF valido"
                  : " "}
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-12 padB20">
          <div className="input-container">
            <form autoComplete="off">
              <input
                id="senha"
                className="input inputForm form-control form-control-lg"
                onChange={(event) =>
                  changes(purificaDom(event.target.value), "senha")
                }
                maxLength="20"
                type="password"
                required
              />
              <label className="label" htmlFor="senha">
                Senha
              </label>
              <div className="avisoCampo">
                {validaSenha(formLogin.senha) || formLogin.senha.length < 1
                  ? " "
                  : "Inserir senha valida"}
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-12 alignSenha">
          <button className="btn btn-link textSenha" onClick={modalSenha}>
            Esqueci minha senha
          </button>
        </div>
        <div className="col-sm-12">
          <Recaptcha
            ref={recaptcha_Auto}
            sitekey={RECAPTCHA_KEY_AUTO}
            size="invisible"
            render="explicit"
          />
          <Recaptcha
            ref={recaptcha_Solar}
            sitekey={RECAPTCHA_KEY_SOLAR}
            size="invisible"
            render="explicit"
          />
        </div>
        <div className="col-sm-12 padB20">
          <button
            className="btnEntrar"
            onClick={logar}
            disabled={
              !validaCpf(formLogin.login) || !validaSenha(formLogin.senha)
            }
          >
            Entrar
          </button>
        </div>

        <form ref={formRef} method="post">
          <input ref={inputRef} hidden value={""} name="authToken" />
        </form>

        <div className="col-sm-12">
          <button className="btnPrimeiraVez" onClick={modalPrimeiroAcesso}>
            Primeira vez aqui? <b className="textCadastrese">Cadastre-se.</b>
          </button>
        </div>
      </div>

      <Modal
        open={openModalRegulamentoAceite}
        close={closeModalRegulamentoAceite}
        size="lg"
      >
        <RegulamentoAceite
          formPage={formRegulamentoAceite}
          action={avancar}
          indicarErros={indicarErrosRegulamentoAceite}
          openRegulamento={abrirRegulamentoUsuario}
        />
      </Modal>

      <Modal
        open={openModalRegulamento}
        close={closeModalRegulamento}
        size="lg"
      >
        <FormRegulamentoAuto linkArquivo={regulamentoUrl} />
      </Modal>
    </div>
  );
};

export default FormLogin;
