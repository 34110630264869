import React from 'react';
import { useHistory } from 'react-router-dom';

import '../styles.scss';
import Modal from '../../../components/Modal/Modal';

const FormConclusaoPreCadastro = ({ open }) => {
    const history = useHistory();

    const voltarHome = () => {
        history.push("/");
    }

    return (
        <Modal 
            open={open} 
            close={voltarHome} 
            size="lg"
        >
            <div className="pageBody">
                <div className="container-fluid existeParceiroPage">
                    <div className="row">
                        <div className='col text-left'>
                            <h1><b>Nova senha concluída</b></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col text-left'>
                            <h5>Sua nova senha foi salva e já pode ser utilizada.</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12 padB20'>
                            <button className="btn btnVoltar btn-lg btn-block" onClick={voltarHome}>Ir para o login</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default FormConclusaoPreCadastro;