import React from 'react';
import './styles.scss';
import Modal from '../../../components/Modal/Modal';

const ConclusaoRecuperarSenha = ({ modalStatus, setModalStatus }) => {

    const close = () => {
        setModalStatus({
            ...modalStatus, 
            conclusaoEsqueciSenha: false
        })
    }

    return (
        <Modal 
          open={modalStatus.conclusaoEsqueciSenha} 
          size="lg" 
          close={  () => {setModalStatus({...modalStatus, conclusaoEsqueciSenha: false})} }
        >
            <div className="pageBody ConclusaoRecuperarSenha">
                <div className="container-fluid">
                    <h2>
                        Nova senha enviada com sucesso.
                    </h2> 
                    <span> Em breve você receberá um e-mail para escolher nova senha. Caso não receba, verifique sua pasta de SPAM.</span>
                    <button  className="btn btnOut btn-block btn-lg btnCustomApp mb-20"  onClick={close}> Ir para Login</button>
                </div>
            </div>
        </Modal>
    )
}

export default ConclusaoRecuperarSenha;