import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Recaptcha from 'react-google-recaptcha';
import './styles.scss';
import services from '../../services';
import footerCompany from '../../assets/img/footer.png';
import PasswordStrength from '../../components/PasswordStrength';
import MessageConfirm from './MessageConfirm';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  //showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';
import globals from '../../globals';

import DOMPurify from 'dompurify';
import { validaCpf } from '../../services/validaCpf.js';
import { validaSenha } from '../../services/validaSenha.js';
const purificaDom = (event) => {
  const limpo = DOMPurify.sanitize(event);
  return limpo;
};

const RECAPTCHA_KEY_AUTO = globals.REACT_APP_RECAPTCHA_KEY_AUTO;
const RECAPTCHA_KEY_SOLAR = globals.REACT_APP_RECAPTCHA_KEY_SOLAR;

const initialDados = {
  cpf: '',
  senha: '',
  confirmarSenha: '',
};

const EsqueciSenha = (props) => {
  const recaptcha_Auto = useRef(null);
  const recaptcha_Solar = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const showMsgW = (msg) => {
    dispatch(showMsgWAction(msg));
    setInformarErros(true);
  };
  const showMsgE = (msg) => {
    dispatch(showMsgEAction(msg));
    setInformarErros(true);
  };
  //const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [dados, setDados] = useState(initialDados);
  const [conclude, setConclude] = useState(false);
  const [informarErros, setInformarErros] = useState(false);
  const token = new URLSearchParams(useLocation().search).get('token') || '';

  const enviarDados = () => {
    if (!dados.cpf || !dados.senha || !dados.confirmarSenha) {
      showMsgW('Informe os campos obrigatórios');
      return;
    }
    if (!services.validate.valideCpf(services.mask.unMask(dados.cpf))) {
      showMsgW('Informe um CPF válido');
      return;
    }
    if (!services.validate.validePasswordSize(dados.senha)) {
      showMsgW('Senha deve ter mínimo 8 caracteres');
      return;
    }
    if (!services.validate.validePasswordNumber(dados.senha)) {
      showMsgW('Senha deve ter ao menos 1 número');
      return;
    }
    if (!services.validate.validePasswordCapital(dados.senha)) {
      showMsgW('Senha deve ter letras maiúsculas e minúsculas');
      return;
    }
    if (!services.validate.validePasswordSpecial(dados.senha)) {
      showMsgW('Senha deve ter caracteres especiais');
      return;
    }
    if (dados.senha !== dados.confirmarSenha) {
      showMsgW('Senha e Confirme sua senha devem ser iguais');
      return;
    }

    setInformarErros(false);

    enviarAuto();
  };

  const enviarAuto = () => {
    const sendObj = {
      userName: services.mask.unMask(dados.cpf),
      token: token,
      senha: dados.senha,
    };
    if (recaptcha_Auto.current) {
      trackPromise(
        recaptcha_Auto.current.executeAsync().then((recaptchaToken) => {
          services.storage.set('captcha', recaptchaToken);
          services.apiAuto
            .confirmarResetSenha(sendObj)
            .then((res) => {
              if (res.fail) {
                enviarSolar();
                return;
              }
              setConclude(true);
            })
            .catch((error) => {
              showMsgE(error.errors.length ? error.errors[0].message : 'Error');
            });
        })
      );
    }
  };

  const enviarSolar = () => {
    const sendObj = {
      userName: services.mask.unMask(dados.cpf),
      token: token,
      senha: dados.senha,
      confirmarSenha: dados.confirmarSenha,
    };
    if (recaptcha_Solar.current) {
      trackPromise(
        recaptcha_Solar.current
          .executeAsync()
          .then((recaptchaToken) => {
            services.storage.set('captcha', recaptchaToken);
            services.apiSolar
              .confirmarResetSenha(sendObj)
              .then((res) => {
                if (res.fail) {
                  showMsgW(
                    'Ocorreu um erro e não foi possível registrar nova senha.'
                  );
                  return;
                }
                setConclude(true);
              })
              .catch((error) => {
                showMsgE(
                  error.errors.length ? error.errors[0].message : 'Error'
                );
              });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
  };

  useEffect(() => {
    async function init() {
      let isAuthenticated = services.storage.get('isAuthenticated');
      let isUserCompany = services.storage.get('isUserCompany');
      if (isAuthenticated && !isUserCompany) {
        history.push(`/home`);
      }
      if (isAuthenticated && isUserCompany) {
        history.push(`/home`);
      }
    }
    init();
  }, []);

  return (
    <div className="EsqueciSenha">
      <div className="container-fluid loginPage pageDisplay">
        <div>
          <div className="form">
            <div className="row">
              <div className="col text-left">
                <h1>
                  <b>Registrar nova senha</b>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <FormControl>
                  <TextField
                    label="cpf"
                    type="text"
                    size="small"
                    inputProps={{ maxLength: 14 }}
                    onChange={(event) => {
                      setDados({
                        ...dados,
                        cpf: purificaDom(event.target.value),
                      });
                    }}
                    value={services.mask.cpf(dados.cpf)}
                    helperText={
                      informarErros && !dados.cpf && 'Campo obrigatório'
                    }
                    error={informarErros && !dados.cpf ? true : false}
                    variant="filled"
                  />
                  <div className="avisoCampo">
                    {!validaCpf(dados.cpf) && dados.cpf.length > 13
                      ? 'Inserir CPF valido'
                      : ' '}
                  </div>
                </FormControl>
              </div>
              <div className="col-sm-12">
                <FormControl>
                  <TextField
                    label="Senha"
                    type="password"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    onChange={(event) => {
                      setDados({ ...dados, senha: event.target.value });
                    }}
                    value={dados.senha}
                    helperText={
                      informarErros && !dados.senha && 'Campo obrigatório'
                    }
                    error={informarErros && !dados.senha ? true : false}
                    variant="filled"
                  />
                  <div className="avisoCampo">
                    {validaSenha(dados.senha) || dados.senha.length < 1
                      ? ' '
                      : 'Inserir senha valida'}
                  </div>
                </FormControl>
              </div>
              <PasswordStrength password={dados.senha} />
              <div className="col-sm-12">
                <FormControl>
                  <TextField
                    label="Confirme sua senha"
                    type="password"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    onChange={(event) => {
                      setDados({
                        ...dados,
                        confirmarSenha: event.target.value,
                      });
                    }}
                    value={dados.confirmarSenha}
                    helperText={
                      informarErros &&
                      !dados.confirmarSenha &&
                      'Campo obrigatório'
                    }
                    error={
                      informarErros && !dados.confirmarSenha ? true : false
                    }
                    variant="filled"
                  />
                  <div className="avisoCampo">
                    {!validaSenha(dados.confirmarSenha) &&
                    dados.confirmarSenha.length > 0
                      ? 'Inserir confimação de valida'
                      : dados.confirmarSenha !== dados.senha
                      ? 'Inserir confimação de valida'
                      : ' '}
                  </div>
                </FormControl>
              </div>

              <div className="col-sm-12">
                <Recaptcha
                  ref={recaptcha_Auto}
                  sitekey={RECAPTCHA_KEY_AUTO}
                  size="invisible"
                  render="explicit"
                />
                <Recaptcha
                  ref={recaptcha_Solar}
                  sitekey={RECAPTCHA_KEY_SOLAR}
                  size="invisible"
                  render="explicit"
                />
                <FormControl>
                  <button
                    className="btn btnCustomSecond btn-lg btn-block"
                    onClick={enviarDados}
                  >
                    Salvar
                  </button>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageConfirm open={conclude} />

      <div className="container-fluid footerPage">
        <div className="row footerPage">
          <div className="col-md-12 footerPage">
            <div>
              <img src={footerCompany} alt=" " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsqueciSenha;
