import React, { useEffect, useState, useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Recaptcha from 'react-google-recaptcha';

import services from '../../../services';
import { useDispatch } from "react-redux"
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './styles.scss';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br"

import Modal from '../../../components/Modal/Modal';
import FormRegulamentoAuto from '../FormRegulamentoAuto';
import PasswordStrength from '../../../components/PasswordStrength';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import { getRegulamentoAtivo } from '../../../services/api-auto';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import globals from '../../../globals';
const RECAPTCHA_KEY = globals.REACT_APP_RECAPTCHA_KEY_AUTO;

const InitialState = {
  nomeCompleto: '',
  sobrenome: '',
  cpf: '',
  dataNascimento: null,
  email: '',
  celular: '',
  cargo: '',
  cnpj: '',
  senha: '',
  confirmarSenha: '',
  razaoSocial: '',
  cnpj: '',
  loginPPAR: '',
  parceiroId: '',
  aceiteTermos: false,
}

const dataInit = {
  regulamentoId: null,
  titulo: "",
  conteudo: "",
  linkPdf: "",
  ativo: true,
  roles: []
}

const FormCompletarCadastroParceiro = ({ formParceiro, modalStatus, setModalStatus }) => {
  const recaptcha = useRef(null);
  const dispatch = useDispatch();
  const [indicarErros, setIndicarErros] = useState(false)
  const showMsgW = (msg) => { setIndicarErros(true); dispatch(showMsgWAction(msg)); }
  const showMsgE = (msg) => { setIndicarErros(true); dispatch(showMsgEAction(msg)); }
  const showMsgS = (msg) => { setIndicarErros(true); dispatch(showMsgSAction(msg)); }

  const [dados, setDados] = useState(InitialState)
  const [permiteEditarCargo, SetPermiteEditarCargo] = React.useState(true)

  const [perfis, setPerfis] = React.useState([]);
  const [regulamentoAuto, setRegulamentoAuto] = React.useState(false);
  const [checkedTermos, setCheckedTermos] = React.useState(false);
  const [cargo, setCargo] = React.useState([]);
  const [passwordStrength, setPasswordStrength] = React.useState(0)

  const passwordSizeValidation = services.validate.validePasswordSize(dados.senha);
  const passwordNumberValidation = services.validate.validePasswordNumber(dados.senha);
  const passwordCapitalValidation = services.validate.validePasswordCapital(dados.senha);
  const passwordSpecialValidation = services.validate.validePasswordSpecial(dados.senha);

  React.useEffect(() => {
    let strength = 0;
    if (passwordSizeValidation) { console.log('size', dados.senha.length); strength++; }
    if (passwordNumberValidation) { strength++; }
    if (passwordCapitalValidation) { strength++; }
    if (passwordSpecialValidation) { strength++; }
    setPasswordStrength(strength);
  }, [dados.senha])

  React.useEffect(() => {
    searchCpf();
  }, [dados.cpf])

  const searchCpf = () => {
    if (services.validate.valideCpf(dados.cpf)) {
      if (recaptcha.current) {
        trackPromise(
          recaptcha.current.executeAsync()
            .then(recaptchaToken => {
              services.storage.set('captcha', recaptchaToken);
              services.apiAuto.searchCpf({
                'cpf': services.mask.unMask(dados.cpf)
              })
                .then(res => {

                  if (!res.fail) {
                    if (res.data !== null) {
                      setDados({
                        ...dados,
                        nomeCompleto: (dados.nomeCompleto) ? dados.nomeCompleto : res.data.nomeCompleto.split(" ")[0],
                        sobrenome: (dados.sobrenome) ? dados.sobrenome : res.data.nomeCompleto.split(" ")[1],
                        dataNascimento: (dados.dataNascimento) ? dados.dataNascimento : res.data.dataNascimento,
                        email: (dados.email) ? dados.email : res.data.email,
                        celular: (dados.celular) ? dados.celular : res.data.phoneNumber,
                        cargo: (dados.nomePerfil) ? dados.nomePerfil : res.data.nomePerfil
                      });

                      SetPermiteEditarCargo(res.data.nomePerfil !== 'Proprietário(a)');
                      SetPermiteEditarCargo(res.data.nomePerfil == 'F&I' || res.data.nomePerfil == 'Vendedor(a)');
                      SetPermiteEditarCargo(res.data?.nomePerfil == null);

                      setCargo(res.data.nomePerfil);

                    }

                  }
                }).catch(error => {
                  showMsgE(error.errors.length ? error.errors[0].message : 'Error');
                })
            })
            .catch(error => {
              console.log(error);
            })
        )
      }
    }
  }

  const changes = (value, campo) => {
    setDados(prevstate => ({ ...prevstate, [campo]: value }));
  }

  const handleAceiteTermosUso = event => {
    setCheckedTermos(event.target.checked);
    dados.aceiteTermos = event.target.checked;
  }

  const handleSelecionarCargo = event => {
    setCargo(event.target.value);
    dados.cargo = event.target.value;
  };

  useEffect(() => {
    getCargoList();
  }, []);

  const getCargoList = () => {
    trackPromise(
      services.apiAuto.perfis()
        .then(res => {
          if (!res.fail) {
            var perfis = res.data.filter(perfil => perfil.displayName == "Vendedor(a)"
              || perfil.displayName == "Proprietário(a)"
              || perfil.displayName == "F&I");
            setPerfis(perfis)
          } else {
            console.log(res.errors ? res.errors.message : 'Erro')
            setTimeout(getCargoList, 4000)
          }
        }).catch(error => {
          console.log(error.errors.length ? error.errors[0].message : 'Error')
          setTimeout(getCargoList, 4000)
        })
    )
  }

  let validadeNomeRetorno = { "valid" : false, "msg": ""}

  const enviarDados = () => {
    if (
      !dados.nomeCompleto ||
      !dados.sobrenome ||
      !dados.cpf ||
      !dados.dataNascimento ||
      !dados.email ||
      !dados.celular ||
      !dados.cargo ||
      !dados.senha ||
      !dados.confirmarSenha
    ) { showMsgW('Informe os campos obrigatórios'); return; }

    validadeNomeRetorno = services.validate.validadeNome(dados.nomeCompleto, "nome");    
    if (!validadeNomeRetorno.valid) { showMsgW(validadeNomeRetorno.msg); return; }
    validadeNomeRetorno = services.validate.validadeNome(dados.sobrenome, "sobrenome");    
    if (!validadeNomeRetorno.valid) { showMsgW(validadeNomeRetorno.msg); return; }
    if (!services.validate.valideCpf(services.mask.unMask(dados.cpf))) { showMsgW('Informe um CPF válido'); return; }
    if (!new moment(dados.dataNascimento).isValid() && dados.dataNascimento) { showMsgW('Informe uma Data de Nascimento válida'); return;}
    if (!services.validate.valideMail(dados.email)) { showMsgW('Informe um E-mail válido'); return; }
    if (!services.validate.valideCellphone(services.mask.unMask(dados.celular))) { showMsgW('Informe um número de celular válido'); return; }
    if (!services.validate.validePasswordSize(dados.senha)) { showMsgW('Senha deve ter mínimo 8 caracteres'); return; }
    if (!services.validate.validePasswordNumber(dados.senha)) { showMsgW('Senha deve ter ao menos 1 número'); return; }
    if (!services.validate.validePasswordCapital(dados.senha)) { showMsgW('Senha deve ter letras maiúsculas e minúsculas'); return; }
    if (!services.validate.validePasswordSpecial(dados.senha)) { showMsgW('Senha deve ter caracteres especiais'); return; }
    if (dados.senha !== dados.confirmarSenha) { showMsgW('Senha e Confirme sua senha devem ser iguais'); return; }
    if (!dados.aceiteTermos) { showMsgW('Necessário aceitar o regulamento para completar o cadastro'); return; }

    setIndicarErros(false);

    

    let formatObt = {
      nome: dados.nomeCompleto + " " + dados.sobrenome,
      senha: dados.senha,
      confirmarSenha: dados.confirmarSenha,
      email: dados.email,
      cpf: services.mask.unMask(dados.cpf),
      phoneNumber: services.mask.unMask(dados.celular),
      aceiteRegulamento: dados.aceiteRegulamento,
      nomePerfil: dados.cargo,
      userName: services.mask.unMask(dados.cpf),

      cnpj: services.mask.unMask(formParceiro.documento),
      razaoSocial: dados.nomeCompleto + " " + dados.sobrenome,

      dataNascimento: dados.dataNascimento
    }

    if (recaptcha.current) {
    
      trackPromise(
        recaptcha.current.executeAsync()
          .then(recaptchaToken => {
            services.storage.set('captcha', recaptchaToken);
            services.apiAuto.preCadastro(formatObt)
              .then(res => {
                if (!res.fail) {
                  setModalStatus({ ...modalStatus, completarCadastroAuto: false, conclusaoPreCadastro: true });
                  setDados({
                    ...dados,
                    nome: '',
                    email: '',
                    celular: '',
                    cargo: '',
                    cnpj: '',
                    razaoSocial: ''
                  });
                } else {
                  showMsgE(res.errors)
                }
              }).catch(error => {
                showMsgW('Erro ao concluir cadastro')
                showMsgE(error.errors.length ? error.errors[0].message : 'Error')
              })
          })
          .catch(error => {
            console.log(error)
          })
      )
    }
  }

  useEffect(() => {
    getAtivo();
  }, []);

  const [data, setData] = useState(dataInit);
  const [linkArquivo, setLinkArquivo] = useState("");

  const getAtivo = async () => {

    const reg = await getRegulamentoAtivo();
    setLinkArquivo(reg.data.linkPdf);
    setData(reg.data);
  }

  return (
    <Modal
      open={modalStatus.completarCadastroAuto}
      close={() => { setModalStatus({ ...modalStatus, completarCadastroAuto: false }) }}
      size="lg"
    >
      <div className="formCompletarCadastroParceiro">
        <div className="container-fluid completarCadastroParceiroPage">

          <div className="row">
            <div className='col text-left'>
              <h1><b>Preencha os campos para se cadastrar.</b></h1>
            </div>
          </div>

          <div className="row">
            <div className='col-sm-12'>
              <Recaptcha
                ref={recaptcha}
                sitekey={RECAPTCHA_KEY}
                size="invisible"
              />
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Nome" type="text" size="small"
                  onChange={event => changes(event.target.value, 'nomeCompleto')}
                  value={dados.nomeCompleto}
                  helperText={indicarErros && !dados.nomeCompleto && 'Campo obrigatório'}
                  error={indicarErros && !dados.nomeCompleto ? true : false}
                  variant="filled"
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Sobrenome" type="text" size="small"
                  onChange={event => changes(event.target.value, 'sobrenome')}
                  value={dados.sobrenome}
                  helperText={indicarErros && !dados.nomeCompleto && 'Campo obrigatório'}
                  error={indicarErros && !dados.nomeCompleto ? true : false}
                  variant="filled"
                />
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="CPF" type="text" size="small"
                  onChange={event => changes(event.target.value, 'cpf')}
                  value={services.mask.cpf(dados.cpf)}
                  helperText={indicarErros && !dados.cpf && 'Campo obrigatório'}
                  error={indicarErros && !dados.cpf ? true : false}
                  inputProps={{ maxLength: 14 }}
                  variant="filled"
                />
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl  >
                <MuiPickersUtilsProvider locale="pt-br" libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Data de Nascimento"
                    format="DD/MM/yyyy"
                    value={dados.dataNascimento}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={novaData => { changes(novaData, 'dataNascimento') }}
                    className="customFilledInput"
                    helperText={indicarErros && !dados.dataNascimento && 'Campo obrigatório'}
                    error={indicarErros && !dados.dataNascimento ? true : false}
                    invalidDateMessage="Data em formato inválido."
                    maxDateMessage="Data não deve ser maior que a data máxima."
                    minDateMessage="Data não deve ser menor que a data mínima."
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="E-mail" type="text" size="small"
                  onChange={event => changes(event.target.value, 'email')}
                  value={dados.email}
                  helperText={indicarErros && !dados.email && 'Campo obrigatório'}
                  error={indicarErros && !dados.email ? true : false}
                  variant="filled"
                />
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Telefone celular" type="text" size="small"
                  onChange={event => changes(event.target.value, 'celular')}
                  value={services.mask.celphone(dados.celular)}
                  helperText={indicarErros && !dados.celular && 'Campo obrigatório'}
                  error={indicarErros && !dados.celular ? true : false}
                  inputProps={{ maxLength: 15 }}
                  variant="filled"
                />
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Cargo" select size="small"
                  onChange={handleSelecionarCargo}
                  value={cargo}
                  helperText={indicarErros && !dados.roleId && 'Campo obrigatório'}
                  error={indicarErros && !dados.cargo ? true : false}
                  variant="filled"
                  // disabled={!permiteEditarCargo}
                >
                  {perfis.map((option) => (
                    <MenuItem key={option.roleId} value={option.displayName}>
                      {option.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Senha" type="password" size="small"
                  onChange={event => changes(event.target.value, 'senha')}
                  value={dados.senha}
                  helperText={indicarErros && !dados.senha && 'Campo obrigatório'}
                  error={indicarErros && !dados.senha ? true : false}
                  variant="filled"
                />
              </FormControl>
            </div>

            <PasswordStrength password={dados.senha} />

            <div className='col-sm-12'>
              <FormControl>
                <TextField label="Confirme sua senha" type="password" size="small"
                  onChange={event => changes(event.target.value, 'confirmarSenha')}
                  value={dados.confirmarSenha}
                  helperText={indicarErros && !dados.confirmarSenha && 'Campo obrigatório'}
                  error={indicarErros && !dados.confirmarSenha ? true : false}
                  variant="filled"
                />
              </FormControl>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-12 acceptTermsWrapper">
              <Checkbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                checked={checkedTermos}
                onChange={handleAceiteTermosUso}
                name="checkedPermission"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                error={indicarErros && !dados.aceiteTermos ? true : false}
              />
              <label className="acceptTerms">
                Li e aceito o <a className="linkRegulamento" onClick={() => { setRegulamentoAuto(true) }}><b><u>regulamento</u></b></a>&nbsp;do programa
              </label>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className='padB20'>
              <button className="btn btnFinalizarCadastro btn-lg btn-block" onClick={enviarDados}>Finalizar cadastro</button>
            </div>
          </div>

        </div>
        <Modal
          open={regulamentoAuto}
          close={() => { setRegulamentoAuto(false) }}
          size="lg"
        >
          <FormRegulamentoAuto linkArquivo={linkArquivo} />
        </Modal>
      </div>
    </Modal>
  );
}

export default FormCompletarCadastroParceiro;