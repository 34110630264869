
import React from 'react';

import { Provider } from 'react-redux';
import { Store } from './store';

import { SnackbarProvider } from 'notistack';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

import './styles/global.scss';
import './assets/fonts/helveticaNowDisplay/stylesheet.css'
import 'antd/dist/antd.css';

import AlertSnackbar from './components/AlertMsg/AlertMsg.js'
import LoadingBar from './components/LoadingBar/LoadingBar.js'

import { ToastContainer } from 'react-toastify';
import UserRoutes from './routes/index';

const notistackRef = React.createRef();
const onClickDismiss = key => () => { notistackRef.current.closeSnackbar(key); }


function App() {
  return (
    <Provider store={Store}>
      <SnackbarProvider maxSnack={1} ref={notistackRef} action={(key) => (<Button onClick={onClickDismiss(key)}><Close /></Button>)}>
          <AlertSnackbar />
      </SnackbarProvider>
      <LoadingBar />
      <UserRoutes />
      <ToastContainer />
    </Provider>
  );
}

export default App;
