import React from 'react';

const Notfound = props => {
    return (
        <div className='flex' style={{minHeight: '50vh'}}>
            Página não encontrada
        </div>
    )
}

export default Notfound;