import React, { memo }  from 'react';

import './styles.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import mask3 from '../../assets/img/mask_3.svg';

const Modal = ({open, close, back, titulo, size, type, children}) => {
    return (
        <Dialog open={open} onClose={close} className={type} aria-labelledby="customized-dialog-title" width={size ? size : 'md'}>
            <DialogTitle id="form-dialog-title">
                <div className="modalTitleWrapper">
                    <IconButton aria-label="close" className="backModal" onClick={close}><ArrowBack /></IconButton>
                    <img src={mask3} />
                </div>
                <Typography>{titulo}</Typography>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default memo(Modal);