export const set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
    return get(key)
}

export const setProduct = (product) => {
    localStorage.setItem('product', product)
}
export const checkProduct = (product) => {
    return localStorage.getItem('product') === product
}
  
export const get = key => {
    let retorno = JSON.parse(localStorage.getItem(key))
    return retorno
}

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem('authSistema'))
    if (!user) return undefined
    return user.profile
}

export const getUserId = () => {
    const user = JSON.parse(localStorage.getItem('authSistema'))
    if (!user) return undefined
    if (!user.profile) return undefined
    if (!user.profile.role) return undefined
    if (!user.profile.role.roleId) return undefined
    return user.profile.role.roleId
}
export const getUserRecrutadorId = () => {
    const user = JSON.parse(localStorage.getItem('authSistema'))
    if (!user) return undefined
    if (!user.profile) return undefined
    if (!user.profile.recrutador) return undefined
    if (!user.profile.recrutador.recrutadorId) return undefined
    return user.profile.recrutador.recrutadorId
}





export const getUserName = () => {
    const user = JSON.parse(localStorage.getItem('SYSTEM_USER'))
    if (!user) return undefined
    return user.userName
}

export const clear = key => {
    localStorage.clear()
}

export const isAdmin = async () => {
  const user = get('SYSTEM_USER')
  if (!user) return false
  if (!user.role) return false
  return user.role.includes('Admin')
}


export const SaveUfId = async (ufId) => {
    try {
        localStorage.setItem('@modal/ufId', ufId);
        return null;
    } catch (error) {
        return error;
    }
}

export const SaveCityId = async (cityId) => {
    try {
        localStorage.setItem('@modal/cityId', cityId);
        return null;
    } catch (error) {
        return error;
    }
}

export const SaveUfName = async (ufName) => {
    try {
        localStorage.setItem('@modal/ufName', ufName);
        return null;
    } catch (error) {
        return error;
    }
}

export const SaveCityName = async (cityName) => {
    try {
        localStorage.setItem('@modal/cityName', cityName);
        return null;
    } catch (error) {
        return error;
    }
}

export const getUfId = async () => {
    try {
        const data = localStorage.getItem('@modal/ufId');
        return data;
    } catch (error) {
        return error;
    }
}

export const getCityId = () => {
    try {
        const data = localStorage.getItem('@modal/cityId');
        return data;
    } catch (error) {
        return error;
    }
}

export const getUfName = (ufName) => {
    try {
        localStorage.getItem('@modal/ufName', ufName);
        return null;
    } catch (error) {
        return error;
    }
}

export const getCityName = (cityName) => {
    try {
        localStorage.getItem('@modal/cityName', cityName);
        return null;
    } catch (error) {
        return error;
    }
}

