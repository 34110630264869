import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import '../styles.scss';

import services from '../../../services';

/////////////////////////////
// redux
import { useDispatch } from "react-redux"
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TitlePage from '../../../components/TitlePage/TitlePage'

import Abas from '../../../components/Abas/Abas';
import TableHeader from '../../../components/Table/TableHeader';
import TablePagination from '../../../components/Table/TablePagination';

const contatosMock = [
  {
    id: 1,
    diasAberto: 2,
    interacoes: 3,
    assunto: 'assunto 1',
    nome: 'nome 1',
    dataCriacao: '2011-10-05T14:48:00.000Z',
    usuario: 'usuario 1'
  }
]

const listAbas = [{nome: 'Em aberto', id: 1}, {nome: 'Finalizados', id: 2}]
const tabelaColunas = ['Data/hora de abertura', 'Usuário' , 'Nome' , 'Assunto', 'Dias em aberto', 'Interações', '']

const FaleConosco = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const [abaActive, setAbaActive] = useState(1);
  const [contatos, setContatos] = useState([]);
  const [busca, setBusca] = useState('');

  const buscar = (value) => { 
    setBusca(value)
    //if (value.length > 2 || value.length === 0) 
    carregarContatos(1, value)
  }
  
  const carregarContatos = (page, busca = '') => {
    if (abaActive===1) {
      trackPromise (
        services.api.contatosEmAberto(page, busca)
        .then(res => {
          if (res.data) {
            setContatos(res.data)
          } else {
            //showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
      )
    } else {
      trackPromise (
        services.api.contatosFinalizados(page, busca)
        .then(res => {
          if (res.data) {
            setContatos(res.data)
          } else {
            //showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
      )
    }
  }

  const selectAba = (abaId) => {
    setBusca('')
    setAbaActive(abaId)
  }

  const gotoDet = (id) => {
    history.push(`/fale-conosco/${id}`)
  }

  const mudarPagina = (page) => {
    carregarContatos(page)
  }

  const gotoNew = (id) => {
    history.push(`/fale-conosco-novo`)
  }

  useEffect(() => {
    carregarContatos(1)
  }, [abaActive]);

  return (
    <div className="faleConoscoContainer">
      <div className="pageHeader noBorder">
        <div className="container text-center">
          <div className="row">
            <div className='col-12'>
              <TitlePage title={'Fale '} titleBold={'Conosco'} />
            </div>
            <div className='col-12'>
              <button className="btn btn-info btn-lg padL40 padR40" onClick={gotoNew}>
                Novo
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className='col-md-12'>
          <div style={{borderBottom: '1px solid #ccc'}}>
            <div className="row">
              <div className='col-md-12 flex center'>
                <Abas 
                  abas={listAbas}
                  active={abaActive}
                  action={selectAba}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className='col-md-4 offset-md-4 mt20 mb20'>
            <FormControl className="noMargin">
              <TextField label="Pesquisar" type="text" size="small"
                value={busca}
                onChange={(e) => buscar(e.target.value)}
              />
            </FormControl>
          </div>

          <div className='col-12'>
            <div className="table-responsive">
              <table className="table">
                <TableHeader columns={tabelaColunas} />
                <tbody>
                  {
                    (!contatos.items || contatos.items.length === 0) &&
                    <tr> 
                      <td colSpan={tabelaColunas.length}> Sem Registros </td> 
                    </tr>
                  }
                  {
                    contatos.items && contatos.items.map((contato) => (
                      <tr key={contato.faleConoscoAssuntoId}>
                        <td> {moment(contato.dataHoraAbertura).format('DD/MM/YYYY HH:mm')} </td>
                        <td> {services.mask.cpf(contato.usuario)} </td>
                        <td> {contato.nome} </td>
                        <td> {contato.descricao} </td>
                        <td className="text-center"> {contato.diasAberto} </td>
                        <td className="text-center"> {contato.interacoes} </td>
                        <td> 
                          <button className="btn btn-icon" onClick={() => gotoDet(contato.faleConoscoAssuntoId)}>
                            <VisibilityIcon /> 
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              
              <TablePagination data={contatos} changePage={mudarPagina} />
              
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
  
}

export default FaleConosco;