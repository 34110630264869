import React, { useState } from 'react';

import './styles.scss';

import services from '../../services';

import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Modal from '../../components/Modal/Modal';

import { trackPromise } from 'react-promise-tracker';
import { validaCpf } from '../../services/validaCpf.js';
import { validaCnpj } from '../../services/validaCnpj.js';

const FormExisteParceiro = ({
  formParceiro,
  setFormParceiro,
  modalStatus,
  setModalStatus,
  setFormatObtCompartilhado,
  procurarParceiroSolar,
  setOpenModalCPFNaoEncontrado,
  validateCNPJ,
  setValidateCNPJ,
}) => {
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  //const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const [indicarErros, setIndicarError] = useState(false);
  //const [validateCNPJ,setValidateCNPJ] = useState(false);

  const continuarSolar = () => {
    setModalStatus({
      ...modalStatus,
      primeiroAcesso: false,
      completarCadastroSolar: true,
    });
  };
  const continuarAuto = () => {
    setModalStatus({
      ...modalStatus,
      primeiroAcesso: false,
      codigoParceiroAuto: true,
    });
  };

  const existeParceiroNaBase = () => {
    if (!formParceiro.documento) {
      setValidateCNPJ(true);
      //setFormParceiro({
      //  ...formParceiro,
      //  cnpj: '',
      //  idParceiro: '',
      //})
      //continuarSolar();
      showMsgE('Campo CNPJ é obrigatório');
      //setIndicarError(true)
      //return false
      return;
    }

    if (
      !services.validate.valideCnpj(
        services.mask.unMask(formParceiro.documento)
      )
    ) {
      showMsgW('Erro de acesso.');
      setIndicarError(true);
      return false;
    }

    setIndicarError(false);

    let formatObt = {
      cnpj: services.mask.unMask(formParceiro.documento),
    };
    procurarParceiroAuto(formatObt);
  };

  const procurarParceiroAuto = (formatObt) => {
    trackPromise(
      services.apiAuto
        .existeParceiroNaBase(formatObt)
        .then((res) => {
          if (!res.fail) {
            if (res.data == null) {
              //procurarParceiroSolar(formatObt)
              //alert('Teste agora');
              setFormatObtCompartilhado(formatObt);
              setOpenModalCPFNaoEncontrado(true);
              //procurarParceiroSolar(formatObt);
            } else {
              setFormParceiro({
                ...formParceiro,
                idParceiro: res.data.parceiroId,
              });
              continuarAuto();
            }
          } else {
            //alert('Solar 2');
            setFormatObtCompartilhado(formatObt);
            setOpenModalCPFNaoEncontrado(true);
            //procurarParceiroSolar(formatObt);
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  /*
  const procurarParceiroSolar = (formatObt) => {
    trackPromise (
      services.apiSolar.existeParceiroNaBase(formatObt).then(res => {

        if (!res.fail) {
          if (res.data == null)
          {
            setFormParceiro({
              ...formParceiro,
              cnpj: '',
              idParceiro: '',
            })
          }
          else
          {
            setFormParceiro({
              ...formParceiro,
              idParceiro: res.data.parceiroId,
            })
          }
        } else {
          setFormParceiro({
            ...formParceiro,
            cnpj: '',
            idParceiro: '',
          })
        }

        continuarSolar();
      }).catch(error => {
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }
  */

  const handleChangeExisteParceiro = (event) => {
    event.persist();
    let value = event.target.value;
    value = services.mask.cnpj(value);
    setFormParceiro((documento) => ({ ...documento, ['documento']: value }));
  };

  const correctCnpj = services.validate.valideCnpj(formParceiro.documento);

  return (
    <Modal
      open={modalStatus.primeiroAcesso}
      close={() => {
        setModalStatus({ ...modalStatus, primeiroAcesso: false });
      }}
      size="xl"
    >
      <div className="pageBody">
        <div className="container-fluid existeParceiroPage">
          <div className="row">
            <div className="col text-left">
              <h1>
                <b>Primeiro acesso</b>
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col text-left">
              <h5>Informe o CNPJ da sua empresa</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <FormControl>
                <TextField
                  type="text"
                  size="small"
                  onChange={handleChangeExisteParceiro}
                  value={formParceiro.documento}
                  helperText={
                    !formParceiro.documento &&
                    validateCNPJ &&
                    'Campo obrigatório'
                  }
                  InputLabelProps={{
                    style: {
                      color:
                        !formParceiro.documento && validateCNPJ
                          ? 'red'
                          : 'rgba(0, 0, 0, 0.87)',
                    },
                  }}
                  //error={indicarErros && !formParceiro.documento ? true : false}
                  variant="filled"
                  FormHelperTextProps={{ className: 'cnpjerrorcolor' }}
                />
                <div className="avisoCampo">
                  {validaCnpj(formParceiro?.documento) ||
                  formParceiro?.documento.length < 1
                    ? ' '
                    : 'Inserir CNPJ valido'}
                </div>
              </FormControl>
            </div>
          </div>

          <div className="row">
            <div className="col-12 padB20">
              <button
                className={
                  correctCnpj
                    ? 'btn btnIrPrimeiroCadastro btn-lg btn-block activeButton'
                    : 'btn btnIrPrimeiroCadastro btn-lg btn-block'
                }
                onClick={existeParceiroNaBase}
                disabled={!validaCnpj(formParceiro?.documento)}
              >
                Ir Para O Cadastro
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormExisteParceiro;
