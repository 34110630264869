import React from 'react';

import { usePromiseTracker } from "react-promise-tracker";
import LinearProgress from '@material-ui/core/LinearProgress';

import './styles.scss';

const LoadingBar = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div className="loadPage"><LinearProgress /></div>
    );
}

export default LoadingBar;