import * as storage from './storage'
import * as apiAuto from './api-auto'
import * as apiSolar from './api-solar'
import * as utils from './utils'
import * as mask from './mask'
import * as validate from './validateForm'

export default {
    storage,
    apiAuto,
    apiSolar,
    utils,
    mask,
    validate
}
