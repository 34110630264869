import { useState, useEffect } from 'react';
import services from '../services';

export const useGetIdiomaFluencias = () => {
  const [state, setState] = useState([]);
  
  useEffect(() => {
    function handleStatusChange(list) {
      setState(list);
    }
    async function get() {
      let retorno = await services.api.idiomaFluencias()
      if (!retorno.fail) {
        handleStatusChange(retorno.data)
      }
    }
    get()
  }, []);

  return state;
}