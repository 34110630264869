import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import './styles.scss';

const FomRegulamentoAceite = ({formPage, action, indicarErros, openRegulamento}) => {
    
  const [checked, setChecked] = React.useState(false);

  const avancar = () => {
    action()
  }

  const abrirRegulamento = () => {
    openRegulamento()
  }

  const handleAceiteRegulamento = event => {
    setChecked(event.target.checked);
    formPage.aceiteRegulamento = event.target.checked;
  };

  return (
    <div className="pageBody">
      <div className="container-fluid regulamentoPage">

        <div className="row">
          <div className='col text-center'>
            <h1>Para continuar, leia e aceite o Regulamento</h1>
          </div>
        </div>

        <div className="row justify-content-center">
            <div className="col-sm-12 acceptTermsWrapper">
              <Checkbox 
                  icon={<RadioButtonUnchecked/>}
                  checkedIcon={<RadioButtonChecked/>}
                  checked={checked} 
                  onChange={handleAceiteRegulamento} 
                  name="checkedPermission" 
                  inputProps={{ 'aria-label': 'primary checkbox' }} 
                  error={indicarErros && !formPage.aceiteRegulamento ? true : false}
              />
              <label className="acceptTerms labelRegulamento">
                Li e aceito o <a className="linkRegulamento" onClick={abrirRegulamento}><b><u>&nbsp;regulamento</u></b></a>
              </label>
            </div>
          </div>

        <div className="row justify-content-center">
          <div className='padB20'>
            <button className="btn btnContinuarIrPrimeiroCadastro btn-lg btn-block" onClick={avancar}>Avançar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FomRegulamentoAceite;