import React from 'react';

import './styles.scss';

import regulamento from '../../../assets/docs/regulamentoAuto.pdf'

const FormRegulamentoAuto = ({linkArquivo}) => {

  return (
    <div className="formRegulamento">
      <div className="container-fluid regulamentoPage">

        <div className="row">
          <div className='col text-left'>
            <h1>Regulamento</h1>
          </div>
        </div>

        <div>
          <object data={linkArquivo} type="application/pdf" width="100%" height="100%">
            <p>
              Seu navegador não suporta pdf, sendo necessário baixar o arquivo do regulamento.
              <a href={linkArquivo} download="regulamento.pdf"> Baixar arquivo</a>
            </p>
          </object>
        </div>

      </div>
    </div>
  );
}

export default FormRegulamentoAuto;