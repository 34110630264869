import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import './styles.scss'

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
        padding: '26px 0 0 0',
        listStyle: 'none',
        fontSize: '11px',

        '& li': {
            margin: 0,
            padding: 0,
        }

    },
});

export default function AgendamentoItemCalendar({listData, action, date}) {
    const classes = useStyles();
    return (
        <>
            {listData.length > 0 && <span className="badgeCalendarItem"></span>}
            <ul className={classes.root} onClick={(e) => action(date)}>
                {listData.map((item) => (
                    <li key={item.content}>
                        <span>
                            {item.content} {item.content > 1 ? 'Reuniões' : 'Reunião'}
                        </span>
                    </li>
                ))}
            </ul>
        </>
    )
}